
import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

interface CustomBoxStyleProps extends BoxStyleProps {
    sx?: Record<string, unknown>;
  }
  

  export const containerStyles: CustomBoxStyleProps = {
    border: "none",
    backgroundColor:"colorBackgroundBrand",
    display: "flex",
    height: "sizeIcon100",
    width: "sizeIcon100",
    fontSize: "fontSize50",
    alignItems: "center",
    justifyContent: "center",
    color: "colorTextWeakest",
    cursor: "pointer",
    transition: "background-color 0.2s",
    outline: "0px",
    _hover: {
      backgroundColor: "colorBackgroundPrimary"
    },
    _focusVisible: {
      backgroundColor: "colorBackgroundPrimary",
      boxShadow: "shadowFocus"
    },
    sx: {
        borderRadius: "borderRadiusCircle!important",
      // propriedades CSS aqui
    }
  };

  export const textStyles: TextStyleProps = {
   fontSize: "fontSize30",
   color: "colorTextNew",
   transition: "background-color 0.2s"
 };

