import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { Avatar, Button, ScreenReaderOnly } from "@twilio-paste/core";
import { useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { Key, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import remarkGfm from 'remark-gfm'
import rehypeRaw  from 'rehype-raw'
import { AppState } from "../store/definitions";
import { FilePreview } from "./FilePreview";
//import { detachFiles } from "../store/actions/genericActions";
import {
    getAvatarContainerStyles,
    getInnerContainerStyles,
    authorStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";
import ReactMarkdown from "react-markdown";
import React from "react";
import { attributesButton,  attributesUser, ButtonEspec } from "../attributesSpec";


const doubleDigit = (number: number) => `${number < 10 ? 0 : ""}${number}`;

export const MessageBubble = ({
    message,
    isLast,
    isLastOfUserGroup,
    focusable,
    updateFocus,
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}) => {
    //const dispatch = useDispatch();
    const [read, setRead] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig, conversation, attachedFiles, messages } = useSelector(
        (state: AppState) => ({
            conversationsClient: state.chat.conversationsClient,
            participants: state.chat.participants,
            users: state.chat.users,
            fileAttachmentConfig: state.config.fileAttachment,
            conversation: state.chat.conversation,
            attachedFiles: state.chat.attachedFiles || [],
            messages: state.chat.messages
        })
    );
    
    const myJson = JSON.stringify(message.attributes);
    //PESQUISA DE SATISFAÇÃO LUCAS ROCHA E SILVA
    //const stars = document.getElementsByClassName("rating__star") as HTMLCollectionOf<HTMLElement>
    const containerStar = document.getElementsByClassName("rating") as HTMLCollectionOf<HTMLElement>
    const stars = containerStar.length > 0 && containerStar[containerStar.length - 1].children as HTMLCollectionOf<HTMLElement>
    const starArray = Array.from(stars) as HTMLElement[];
    const [result, setResult] = useState<number | undefined>()

    const [ buttons, setButtons ] = useState<attributesButton | undefined>()
    const [ buttonHasAttribute, setButtonHasAttribute ] = useState<boolean | undefined>()

    function executeStarRating(stars: HTMLElement[]) {
        const starClassInactive = "far";
        const starsLength = starArray.length;
        let i;
        Array.from(stars).map((star) => {
          star.onclick = () => {
            
            i = starArray.indexOf(star);

            if (star.className.indexOf(starClassInactive) !== -1) {
              setResult(i + 1)
              for (i; i >= 0; --i) {
                starArray[i].classList.remove("rating__star", "far", "fa-star");
                starArray[i].classList.add("rating__star", "fas", "fa-star");
              }
            } else {
              setResult(i)
              for (i; i < starsLength; ++i) {
                starArray[i].classList.remove("rating__star", "fas", "fa-star");
                starArray[i].classList.add("rating__star", "far", "fa-star");
              }
            }
          };
        });
      }
      

     

    useEffect(() => {
        if(stars && containerStar){
            //console.log();
            executeStarRating(starArray);
        }
       
      }, [stars, starArray, containerStar]);

    useEffect(()=>{
        if (message.attributes && message.attributes.hasOwnProperty('buttons')) {
            const buttonJson:attributesButton = JSON.parse(myJson)
            setButtons(buttonJson);
            setButtonHasAttribute(buttonJson.buttons.some((element)=>  'attribute' in element))
        }    

    }, [message.attributes.hasOwnProperty('buttons')])

    const ChangeUserName = () =>{
        let nameAttribute:attributesUser | undefined
        if(message.attributes.hasOwnProperty('name')){
            nameAttribute = JSON.parse(myJson)
            users.map(async(user)=> await user.updateFriendlyName(nameAttribute.name))
        }
    
    }

    useEffect(()=>{[
        ChangeUserName()
    ]}, [message.attributes.hasOwnProperty('nome')])

    const author = users?.find((u) => u.identity === message.author)?.friendlyName || message.author;

    //let botName = messages[1].author

    // let changeAvatar = botName !== author ?
    // 'https://plurismidia.com.br/wp-content/uploads/2016/12/icone.png' :
    // 'pirata.png'
    // //console.log(author);



    const messageRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
        
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={index} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>Media messages are not supported</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleFocus = () => {
        // Necessary since screen readers can set the focus to any focusable element
        updateFocus(message.index);
    };

    // RODRIGO SOUZA - ENVIAR MENSAGEM VIA BOTÃO
    const buttonClick = async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        if (conversation !== undefined) {
            if (e.currentTarget.value.indexOf("https://") !== -1 || e.currentTarget.value.indexOf("http://") !== -1) {
                window.open(e.currentTarget.value);
            } else {
                let preparedMessage = conversation.prepareMessage();
                //LUCAS ROCHA validação se o botão tem um outro attributo
                if(buttonHasAttribute){
                    preparedMessage = preparedMessage.setBody(String(result));
                    setResult(0)
                }else{
                    preparedMessage = preparedMessage.setBody(e.currentTarget.value);
                }
                

                // attachedFiles.forEach((file: File) => {
                //     const formData = new FormData();
                //     formData.append(file.name, file);
                //     preparedMessage.addMedia(formData);
                // });
                await preparedMessage?.build().send();

                //dispatch(detachFiles(attachedFiles));
            }
        }
    };

    const ContainNumber = (button: ButtonEspec)=> {
        if( button.value){
            if(/\d/.test( button.value)){
                return `**${ button.value}**`
            }else {
                return  button.value
            }
        }
    }

    function listarButtons(button: string) {
    //LUCAS ROCHA validação para apenas renderizar quandou houver um value no array de buttons para poder adicionar outros atributos
        if(!button){
            //console.log(button)
            return null
        }


        return (
        //    <Box key={index} style={{margin:'0.5rem 0'}} display="inline-block" width="100%" marginY="spaceNegative10">
                <Button
                    size="small"
                    fullWidth={true}
                    value={button}
                    variant={
                        !button.includes("http") && !button.includes("https")
                            ? "secondary"
                            : "link"
                    }
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        buttonClick(e);
                    }}
                    disabled={result == undefined && buttonHasAttribute  ? true : false}
                    
                >
                   <ReactMarkdown components={{p:React.Fragment}}>{button}</ReactMarkdown>
                </Button>
            //</Box>
        );
    }

    

    // Rodrigo SOUZA - AVATAR do BOT 
    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                {!belongsToCurrentUser && (
                    <Box
                        {...getAvatarContainerStyles(!isLastOfUserGroup)}
                        data-testid="avatar-container"
                        backgroundColor="transparent"
                    >
                        {
                            <Avatar
                              size="sizeIcon60"
                              name="icone Pluris"
                              src="https://plurismidia.com.br/wp-content/uploads/2016/12/icone.png"
                            
                          />
                        }
                    </Box>
                )}
                <Box display='block' {...getInnerContainerStyles(belongsToCurrentUser)}>
                    <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space20">
                        <Text {...authorStyles} as="p" aria-hidden style={{ textOverflow: "ellipsis" }} title={author}>
                            {author}
                        </Text>
                        <ScreenReaderOnly as="p">
                            {belongsToCurrentUser
                                ? "You sent at"
                                : `${users?.find((u) => u.identity === message.author)?.friendlyName} sent at`}
                        </ScreenReaderOnly>
                        <Text {...timeStampStyles} as="p">
                            {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                                message.dateCreated.getMinutes()
                            )}`}
                        </Text>
                    </Flex>
                    <Text as="div" ref={bottomRef} {...bodyStyles}>
                        {message.body && !belongsToCurrentUser ?
                        <ReactMarkdown
                        // components={{ p: React.Fragment}}
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        >{message.body}</ReactMarkdown> : message.body}
                        {/* {message.body ? parseMessageBody(message.body, belongsToCurrentUser) : null} */}
                        
                    </Text>
                    
                    {buttons ? buttons.buttons.map((button, index)=> {
                        if(button.attribute){
                            return null
                        }
                        return (
                            <Box className="chatAttributes" key={index} style={{margin:'0.5rem 0'}} display="inline-block" width="100%" marginY="spaceNegative10">  
                                {button.title && <Text as="div"  {...bodyStyles}  style={{textAlign:'left'}}>{button.title}</Text>}
                                {listarButtons(button.value)}
                            </Box>
                        )
                        
                        }, this) : null}
                    
                    {/* {message.type === "media" ? renderMedia() : null} */}
                    { bottomRef.current?.scrollIntoView({behavior: 'smooth'}) /* rolagem IPHONE */ }
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        Lido
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
