
import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import { innerContainerStyles, outerContainerStyles, popIn, popOut } from "./styles/RootContainer.styles";
import { useEffect, useState } from "react";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};


export function RootContainer() {
    
    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));

    const [ zoom, setZoom ] = useState(Math.round(window.devicePixelRatio * 100))
    const [ size, setSize] = useState(window.innerHeight)

    window.addEventListener('resize', ()=>{
        setZoom(Math.round(window.devicePixelRatio * 100))
        setSize(window.innerHeight)
    })

    const CheckZoom = ()=>{
        let altura = ''

        if(zoom < 100){
            altura = '540px'
        }else{
            altura ='83.2vh'
        }

        if(size > 649){
            altura = '540px'
        }else{
            altura = '83.2vh'
        }

        return altura
    }

    useEffect(()=>{
        CheckZoom()
    }, [zoom, size])


    return (
        <Box>
            <Box style={{zIndex:'9999'}} {...outerContainerStyles}>
                {expanded && (
                    <Box
                    style={{height:CheckZoom()}} 
                    id="displayChat" 
                    animation={!expanded ? `${popOut} 0.2s ease-in-out` : `${popIn} 0.2s ease-in-out`} 
                    data-test="root-container" 
                    {...innerContainerStyles}
                    >
                        {getPhaseComponent(currentPhase)}
                    </Box>
                )}
                <EntryPoint />
            </Box>
        </Box>
    );
    
}
