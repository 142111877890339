import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const formStyles: BoxStyleProps = {
    padding: "space40",
    paddingTop: "space80",
    overflow: "auto",
    textAlign: "center"
};

export const titleStyles: TextStyleProps = {
    fontSize: "fontSize70",
    marginBottom: "space60"
};

export const introStyles: TextStyleProps = {
    marginBottom: "space70"
};


export const buttonStyles: BoxStyleProps = {
    marginBottom: "space70",
    backgroundColor: "colorBackgroundPrimaryStronger",
    boxSizing: "border-box",
    width: "auto",
    appearance: "none",
    display: "inline-block",
    border:"none",
    outline: "none",
    transition: "background-color 100ms ease-in 0s, box-shadow 100ms ease-in 0s, color 100ms ease-in 0s",
    fontWeight: "fontWeightBold",
    textDecoration: "none",
    position: "relative",
    margin: "space0",
    cursor: "pointer",
    color: "colorTextWeakest",
    boxShadow: "shadowBorder",
    borderRadius: "borderRadius20",
    fontSize: "100%",
    lineHeight: "lineHeight10",
    fontFamily:"fontFamilyText",

    _hover: {
        backgroundColor: "colorBackgroundPrimaryStrong"
    },
    _focusVisible: {
        backgroundColor: "colorBackgroundPrimaryStrong",
        boxShadow: "shadowFocus"
    }
};

// export const containerStyles: BoxStyleProps = {
//     border: "none",
//     backgroundColor: "colorBackgroundPrimaryStrong",
//     display: "flex",
//     height: "sizeIcon100",
//     width: "sizeIcon100",
//     fontSize: "fontSize50",
//     alignItems: "center",
//     justifyContent: "center",
//     borderRadius: "borderRadiusCircle",
//     color: "colorTextWeakest",
//     cursor: "pointer",
//     transition: "background-color 0.2s",
//     outline: "0px",
//     _hover: {
//         backgroundColor: "colorBackgroundPrimary"
//     },
//     _focusVisible: {
//         backgroundColor: "colorBackgroundPrimary",
//         boxShadow: "shadowFocus"
//     }
// };


