import { Avatar } from "@twilio-paste/core";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { containerStyles, textStyles } from "./styles/EntryPoint.styles";


export const EntryPoint = () => {
    
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);

    const [ toggle, setToggle ] = useState(false)

    return (
        <Box as='div' className="button-abrirChat-wrapper" display='flex' justifyContent='center' style={{gap:'0.7rem'}} alignItems='center'>
             <Text {...textStyles} style={{
                opacity:toggle && !expanded ? 1 : 0,
                display:toggle && !expanded ? 'block' : 'none',
                background:'#ffffff',
                borderRadius:'0.5rem',
                padding:'0.1rem 0.3rem'
                }} transition='all .2s' as='p'>Abrir o chat</Text>
            <Box
                onMouseEnter={()=> setToggle(true)}
                onMouseLeave={()=> setToggle(false)}
                as="button"
                data-test="entry-point-button"
                onClick={() => {
                    dispatch(changeExpandedStatus({ expanded: !expanded }))
                }}
                onMouseDown={(e)=> e.preventDefault()}
                {...containerStyles}
                className="button-abrirChat button-sem-after"
                //style={{backgroundColor:'#424242'}}
            >
                {expanded ? (
                    <ChevronDownIcon decorative={false} title="Minimizar chat" size="sizeIcon80" />
                ) : (
                    <img style={{height:'2.1rem'}} src="https://chatinstitucional.plurismidia.com.br/images/pluris-icon-white.png" alt="pluris-icon" />
                )}
            </Box>
        </Box>

    );

};